/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationHE from "./locales/languages/he/translation.json";
import translationENG from "./locales/languages/en/translation.json";

// flags
import usFlag from "./assets/images/flags/us.jpg";
import ilFlag from "./assets/images/flags/il.png";

// the translations
export const resources = {
  // rs: {
  //   keys: ["rs"],
  //   translation: translationRS,
  //   label: "Russian",
  //   flag: russiaFlag,
  //   // direction: "ltr",
  // },
  he: {
    keys: ["he", "he-IL"],
    // direction: "rtl",
    label: "עברית",
    translation: translationHE,
    flag: ilFlag,
  },
  en: {
    keys: ["en", "eng", "en-US"],
    translation: translationENG,
    label: "English",
    // direction: "ltr",
    flag: usFlag,
  },
};
const defaultLanguageKey = "en";

//#region getLanguage
export const getUserLanguageKey = () => {
  //#region: localStorage lagnguage
  if (localStorage.I18N_LANGUAGE) return localStorage.I18N_LANGUAGE;
  //#endregion: localStorage lagnguage

  //#region: Check user lagnguage from device
  let userLanguage = navigator.language || navigator.userLanguage;
  let find = false;

  if (userLanguage) {
    for (var lng in resources) {
      if (
        resources[lng].keys.find(
          (key) => key.toLocaleLowerCase() === userLanguage.toLocaleLowerCase()
        )
      ) {
        find = true;
        userLanguage = lng;
        localStorage.setItem("I18N_LANGUAGE", userLanguage);
        return userLanguage;
      }
    }

    //#region
    const userLanguages = navigator.languages;
    if (!find && userLanguages) {
      for (var lng in resources) {
        userLanguages.forEach((ulng) => {
          if (
            resources[lng].keys.find(
              (key) => key.toLocaleLowerCase() === ulng.toLocaleLowerCase()
            )
          ) {
            find = true;
            userLanguage = lng;
            localStorage.setItem("I18N_LANGUAGE", userLanguage);
            return userLanguage;
          }
        });
      }
    }
    ///#endregion
  }
  //#endregion: Check user lagnguage from device

  if (find) return userLanguage;
  else {
    localStorage.setItem("I18N_LANGUAGE", defaultLanguageKey);
    return defaultLanguageKey;
  }
};
//#endregion getLanguage

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getUserLanguageKey(), //localStorage.getItem("I18N_LANGUAGE") || "en",
    fallbackLng: defaultLanguageKey, // use default if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });


export default i18n;
