// import googleCSV from "contact.csv";
// import contactOutlook from "contact_outlook.csv";
import iosVCF from "./contact.vcf"

// Select languages
import i18n from "./i18n"
import { withTranslation } from "react-i18next"
import { Dropdown } from "react-bootstrap"
import MetaTags from "react-meta-tags"

// images
import logoUidb from "./assets/img/logo-uidb.png"
import orChuImg from "./assets/img/or.png"
import diskIcon from "./assets/img/disk.png"
import phoneIcon from "./assets/img/phone.png"
import envelopeIcon from "./assets/img/envelope.png"
import linkedinIcon from "./assets/img/in.png"
import websiteIcon from "./assets/img/earth.png"
import githubIcon from "./assets/img/github.png"
import favicon from "./assets/img/favicon/favicon.ico"
import faviconPNG from "./assets/img/favicon/icon.png"
import spnader from "./assets/img/spander.png"
import maxsens from "./assets/img/maxsens.png"

// #Hooks
import {
	useParams,
	BrowserRouter as Router,
	Route,
	Link,
} from "react-router-dom"
import { createBrowserHistory } from "history"

import "./style.css"
import "./index.css"

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

// Router
const history = createBrowserHistory()

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1]
if (path) {
	history.replace(path)
}

const AppWithRouter = props => (
	<Router>
		<Route path={["/:language", "/"]}>
			<App {...props} />
		</Route>
	</Router>
)

function App(props) {
	// const isIOS = () => {
	//   return (
	//     [
	//       "iPad Simulator",
	//       "iPhone Simulator",
	//       "iPod Simulator",
	//       "iPad",
	//       "iPhone",
	//       "iPod",
	//     ].includes(navigator.platform) ||
	//     // iPad on iOS 13 detection
	//     (navigator.userAgent.includes("Mac") && "ontouchend" in document)
	//   );
	// };

	const saveInfoFile = () => {
		// const ansIsIOS = isIOS();
		// if (ansIsIOS)
		window.open(iosVCF)
		// else window.open(googleCSV);
	}
	// const saveInfoFile = () => {
	//   const ansIsIOS = isIOS();
	//   if (ansIsIOS) return iosVCF;
	//   // window.open(iosVCF);
	//   else return googleCSV; //window.open(googleCSV);
	// };

	const { language } = useParams()

	if (i18n.language !== language) i18n.changeLanguage(language)
	// let query = useQuery();

	return (
		<div>
			<MetaTags>
				{/* <title>Page 1</title> */}
				<meta property="image" content={orChuImg} />
				<meta property="og:image" content={orChuImg} />
				<link rel="icon" href={favicon} />

				<link rel="apple-touch-icon" href={logoUidb} />
				<link rel="shortcut icon" href={favicon} />
				<link rel="apple-touch-icon-precomposed" href={faviconPNG} />
			</MetaTags>
			<div className="select-language-area">
				<Dropdown>
					<Dropdown.Toggle variant="light" id="dropdown-basic" size="sm">
						{language || i18n.language}
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Link
							id="language_link"
							to={`/${i18n.language === "en" ? "he" : "en"}`}
						></Link>
						<Dropdown.Item
							// href="/en"
							onSelect={e => {
								// i18n.changeLanguage("en");
								document.getElementById("language_link").click()
							}}
						>
							English
						</Dropdown.Item>
						<Dropdown.Item
							onSelect={e => {
								// i18n.changeLanguage("he");
								document.getElementById("language_link").click()
							}}
							// href="/he"
						>
							עברית
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>{" "}
			<div className="header-area">
				<span className="line" />
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="logo">
								<a href="https://ui-db.com">
									<img src={logoUidb} alt="" />
								</a>
								{/* <h2>
                  systems <br />
                  development
                </h2> */}
							</div>
							<div className="author">
								<img src={orChuImg} alt="" />
							</div>

							<div title={props.t("Or Chuban/ title")} className="author-info">
								<h2>{props.t("Or Chuban")}</h2>
								<p>{props.t("CEO UI-DB")}</p>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="share" /> */}
			</div>
			<div className="section-area">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="main-menu">
								<ul>
									<li>
										<a
											className="save-cursor"
											href={"false"}
											onClick={saveInfoFile}
											// download={`${isIOS()?"OrChuban.vcf":"OrChuban.csv"}`}
											download={"OrChuban.vcf"}
										>
											<span className="icon">
												<img src={diskIcon} alt="save business card" />
											</span>
											{props.t("save")}
										</a>
									</li>
									<li>
										<a href="tel:+972542883622">
											<span className="icon">
												<img src={phoneIcon} alt="call me" />
											</span>
											{props.t("call me")}
										</a>
									</li>
									<li>
										<a href="mailto:or@ui-db.com?Subject=Hey Or Chuban">
											<span className="icon">
												<img src={envelopeIcon} alt="email" />
											</span>
											{props.t("email")}
										</a>
									</li>

									<li>
										<a href="https://ui-db.com">
											<span className="icon">
												<img src={websiteIcon} alt="website icon" />
											</span>
											{props.t("UI-DB website")}
										</a>
									</li>
									{/* <li>
                    <a href="https://www.waze.com/ul?ll=32.767761,34.972618&zoom=17">
                      <span className="icon">
                        <img src="assets/img/map.png" alt="" />
                      </span>
                      ADDRESS
                    </a>
                  </li> */}
									<li>
										<a href="https://www.linkedin.com/in/or-chuban-028280125">
											<span className="icon">
												<img src={linkedinIcon} alt="linkedin" />
											</span>
											{props.t("linkedin")}
										</a>
									</li>
									<li>
										<a href="https://github.com/orchuban">
											<span className="icon">
												<img src={githubIcon} alt="github" />
											</span>
											{props.t("github")}
										</a>
									</li>
									{/* <li>
                    <a href="https://ui-db.com">
                      <span className="icon">
                        <img src="assets/img/info.png" alt="" />
                      </span>
                      ABOUT US
                    </a>
                  </li> */}
								</ul>
							</div>

							<div class="project">
								<a href="https://spander.ui-db.com/">
									<img src={spnader} alt="spander" />
								</a>
								<a href="https://maxsense.live/">
									<img src={maxsens} alt="maxsens" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default withTranslation()(AppWithRouter)
